import {
  useCreateCancelSurveyMutation,
  CancelSurveyInput,
  useGetCancelSurveyBySubscriptionIdQuery,
} from "services/graphql";

export function useCancelSurvey(subscriptionId: string) {
  const {
    data: cancelSurveyData,
    loading: surveyLoading,
  } = useGetCancelSurveyBySubscriptionIdQuery({
    variables: {
      subscriptionId,
    },
  });
  const [
    createCancelSurvey,
    { loading: submitSurveyLoading, error: submitSurveyError },
  ] = useCreateCancelSurveyMutation();

  const submitCancelSurvey = async (input: CancelSurveyInput) => {
    return createCancelSurvey({
      variables: {
        input,
      },
    });
  };

  return {
    submitCancelSurvey,
    surveyLoading,
    submitSurveyLoading,
    submitSurveyError,
    cancelSurveyData: cancelSurveyData?.cancelSurvey,
  };
}
