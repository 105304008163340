import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory } from "react-router-dom";
import useUserSubscription from "hooks/Subscriptions/useUserSubscription";
import { useSubscriptionCancel } from "hooks/Subscriptions/useSubscriptionCancel";
import { useCancelSurvey } from "hooks/Subscriptions/useCancelSurvey";
import Alert from "app/components/Alert";
import LoaderCentered from "app/components/Loader/LoaderCentered";
import { searchClient } from "app/components/Algolia/constants";
import { AlgoliaIndexes } from "app/components/Algolia/types";
import { H1, P1 } from "app/components/Typography";
import Button from "app/components/Button";
import Modal, { CloseModalButton } from "app/components/Modal";
import Icon, { Close, ChevronLeft } from "app/components/Icon";
import styled from "styled-components";
import { CancelReason } from "services/graphql";
import { AccountBodyWrapper } from "../AccountBodyWrapper";
import styles from "./styles.module.scss";

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 600px;
  padding: 24px;
  border-radius: 24px;
`;

interface CancelSurveyData {
  reason: CancelReason | null;
  otherReason: string;
  overallExperienceRating: number;
  joinAgainReason: string;
  missingContent?: {
    danceStyle?: boolean;
    classFormat?: boolean;
    music?: boolean;
    choreographer?: boolean;
    other?: boolean;
  };
  additionalFeedback: string;
}

interface UserSubscriptionData {
  me: {
    primarySubscription: {
      id: string;
      currentTermEnd: number;
    };
  };
}

function useClassCount() {
  const [classCount, setClassCount] = useState<number>(1700);

  useEffect(() => {
    const getClassCount = async () => {
      const index = searchClient.initIndex(AlgoliaIndexes.classes);
      const { nbHits } = await index.search("", {
        hitsPerPage: 0,
      });
      setClassCount(Math.floor(nbHits / 10) * 10);
    };

    getClassCount();
  }, []);

  return classCount;
}

const Cancel: React.FC = () => {
  const history = useHistory();
  const classCount = useClassCount();
  const [data = {} as UserSubscriptionData, loading] = useUserSubscription();
  const [isCanceling, setIsCanceling] = useState(false);

  const {
    me: { primarySubscription },
  } = data;
  const { id: subscriptionId } = primarySubscription;

  const {
    submitCancelSurvey,
    cancelSurveyData,
    surveyLoading,
    submitSurveyError,
  } = useCancelSurvey(subscriptionId);

  const [newSurveyData, setNewSurveyData] = useState<CancelSurveyData>(() => ({
    reason: cancelSurveyData?.reason ?? null,
    otherReason: cancelSurveyData?.otherReason ?? "",
    overallExperienceRating: cancelSurveyData?.overallExperienceRating ?? 0,
    joinAgainReason: cancelSurveyData?.joinAgainReason ?? "",
    missingContent: {},
    additionalFeedback: cancelSurveyData?.additionalFeedback ?? "",
  }));

  const [showMissingContent, setShowMissingContent] = useState(false);
  const [showTechnicalSupport, setShowTechnicalSupport] = useState(false);
  const [hasAgreed, setHasAgreed] = useState(false);

  const {
    subscriptionCancel,
    subscriptionCancelError,
  } = useSubscriptionCancel();

  useEffect(() => {
    if (cancelSurveyData && !surveyLoading) {
      setNewSurveyData({
        reason: cancelSurveyData.reason,
        otherReason: cancelSurveyData.otherReason ?? "",
        overallExperienceRating: cancelSurveyData.overallExperienceRating,
        joinAgainReason: cancelSurveyData.joinAgainReason ?? "",
        missingContent: {},
        additionalFeedback: cancelSurveyData.additionalFeedback ?? "",
      });
    }
  }, [cancelSurveyData]);

  if (loading || surveyLoading || isCanceling) {
    return <LoaderCentered />;
  }

  const handleReasonChange = (reason: CancelReason) => {
    setNewSurveyData(prev => ({ ...prev, reason }));
    setShowMissingContent(reason === CancelReason.MissingContent);
    setShowTechnicalSupport(
      reason === CancelReason.TechnicalIssues ||
        reason === CancelReason.HardToUse
    );
  };

  const handleMissingContentChange = (
    key: keyof CancelSurveyData["missingContent"]
  ) => {
    setNewSurveyData(prev => ({
      ...prev,
      missingContent: {
        ...prev.missingContent,
        [key]: !prev.missingContent?.[key],
      },
    }));
  };

  const handleSubscriptionCancel = async (): Promise<void> => {
    if (!hasAgreed || !newSurveyData.reason) {
      return;
    }

    setIsCanceling(true);
    try {
      // First submit the survey data
      console.log("newSurveyData", newSurveyData);
      await submitCancelSurvey({
        reason: newSurveyData.reason,
        otherReason:
          newSurveyData.reason === CancelReason.Other
            ? newSurveyData.otherReason
            : undefined,
        joinAgainReason: newSurveyData.joinAgainReason,
        overallExperienceRating: newSurveyData.overallExperienceRating,
        additionalFeedback: newSurveyData.additionalFeedback,
        subscriptionId,
      });

      // Then cancel the subscription
      await subscriptionCancel({
        subscriptionId,
        cancelReason: newSurveyData.reason,
      });

      // Redirect based on reason
      if (
        newSurveyData.reason === CancelReason.MissingContent &&
        showMissingContent
      ) {
        setIsCanceling(false);
        return; // Don't redirect until they submit missing content
      }

      if (
        newSurveyData.reason === CancelReason.TechnicalIssues ||
        newSurveyData.reason === CancelReason.HardToUse
      ) {
        setIsCanceling(false);
        return; // Don't redirect until they contact support
      }

      history.push("/account/cancel-success");
    } catch (error) {
      // Error handling is done through the hooks' error states
      setIsCanceling(false);
    }
  };

  return (
    <div>
      <Helmet title="Cancel | Online Dance Classes and Tutorials" />
      <div className={styles.container}>
        <H1 className={styles.mainTitle}>
          Hey, we&apos;re sorry to see you go! Are you sure you want to cancel?
        </H1>

        <div className={styles.content}>
          <div className={styles.leftColumn}>
            <div className={styles.section}>
              <H1>Here&apos;s what you&apos;ll lose</H1>
              <ul className={styles.lossList}>
                <li>
                  <Icon
                    as={Close}
                    width="16px"
                    height="16px"
                    className={styles.lossIcon}
                  />
                  {classCount}+ classes for all skill levels
                </li>
                <li>
                  <Icon
                    as={Close}
                    width="16px"
                    height="16px"
                    className={styles.lossIcon}
                  />
                  New class releases
                </li>
                <li>
                  <Icon
                    as={Close}
                    width="16px"
                    height="16px"
                    className={styles.lossIcon}
                  />
                  Guided programs for Hip-Hop, Heels, Ballet & more
                </li>
                <li>
                  <Icon
                    as={Close}
                    width="16px"
                    height="16px"
                    className={styles.lossIcon}
                  />
                  Exclusive content from your favorite choreographers
                </li>
              </ul>
            </div>
          </div>

          <div className={styles.rightColumn}>
            <div className={styles.section}>
              <div className={styles.sectionContent}>
                <H1>Email STEEZY Support</H1>
                <P1>
                  We would love to hear how your STEEZY Studio experience is
                  going and if there is anything we can do to improve.
                </P1>
              </div>
              <Button
                variant="primary"
                onClick={() => {
                  window.location.href = "mailto:support@steezy.co";
                }}
              >
                Send message
              </Button>
            </div>
          </div>
        </div>

        <div className={styles.neverMindSection}>
          <div className={styles.neverMindTextContainer}>
            <P1 className={styles.neverMindText}>
              Important: By canceling your subscription, you will lose access to
              all the features associated with your plan.
            </P1>
            <Button
              variant="secondary"
              onClick={() => history.push("/account/subscription")}
              className={styles.neverMindButton}
            >
              <Icon
                as={ChevronLeft}
                width="16px"
                height="16px"
                className={styles.buttonIcon}
              />
              Never mind! Take me back to my account
            </Button>
          </div>
        </div>

        <div className={styles.surveySection}>
          <div className={styles.surveyContent}>
            <div className={styles.surveyQuestion}>
              Please share your reason for leaving
              <p className={styles.required}>REQUIRED</p>
            </div>
            <div className={styles.radioGroup}>
              {[
                {
                  value: CancelReason.NoTime,
                  label: "I don't have the time to dance",
                },
                {
                  value: CancelReason.TakingBreak,
                  label: "I'm just taking a break, I'll be back",
                },
                {
                  value: CancelReason.MissingContent,
                  label: "Missing the type or style of dance I wanted",
                },
                {
                  value: CancelReason.NotEnoughClasses,
                  label: "Not enough classes I want to take",
                },
                {
                  value: CancelReason.HardToUse,
                  label: "Site is too hard to use",
                },
                {
                  value: CancelReason.TechnicalIssues,
                  label: "Technical issues",
                },
                { value: CancelReason.TooExpensive, label: "Too expensive" },
                { value: CancelReason.Other, label: "Other" },
              ].map(({ value, label }) => (
                <label
                  key={value}
                  className={styles.radioLabel}
                  htmlFor={`reason-${value}`}
                >
                  <input
                    id={`reason-${value}`}
                    type="radio"
                    name="cancelReason"
                    value={value}
                    checked={newSurveyData.reason === value}
                    onChange={() => handleReasonChange(value)}
                  />
                  <span>{label}</span>
                </label>
              ))}
              {newSurveyData.reason === CancelReason.Other && (
                <input
                  type="text"
                  className={styles.textInput}
                  value={newSurveyData.otherReason}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const target = e?.target;
                    setNewSurveyData(prev => ({
                      ...prev,
                      otherReason: target?.value || "",
                    }));
                  }}
                  placeholder="Tell us more..."
                />
              )}
            </div>
          </div>

          {showMissingContent && (
            <StyledModal
              isOpen={showMissingContent}
              onBackgroundClick={() => setShowMissingContent(false)}
              onEscapeKeydown={() => setShowMissingContent(false)}
            >
              <div className={styles.modalHeader}>
                <H1>Something missing from STEEZY?</H1>
                <CloseModalButton
                  onClick={() => setShowMissingContent(false)}
                  variant="light"
                />
              </div>
              <P1>
                We are always expanding our library, so let us know what
                you&apos;re looking for!
              </P1>
              <div className={styles.checkboxGroup}>
                {[
                  { key: "danceStyle", label: "dance style" },
                  { key: "classFormat", label: "class format" },
                  { key: "music", label: "music" },
                  { key: "choreographer", label: "choreographer" },
                  { key: "other", label: "Other" },
                ].map(({ key, label }) => (
                  <label
                    key={key}
                    className={styles.checkboxLabel}
                    htmlFor={`missing-${key}`}
                  >
                    <input
                      id={`missing-${key}`}
                      type="checkbox"
                      checked={
                        newSurveyData.missingContent?.[
                          key as keyof CancelSurveyData["missingContent"]
                        ] || false
                      }
                      onChange={() =>
                        handleMissingContentChange(
                          key as keyof CancelSurveyData["missingContent"]
                        )
                      }
                    />
                    <span>{label}</span>
                  </label>
                ))}
              </div>
              <Button
                variant="primary"
                onClick={() => setShowMissingContent(false)}
              >
                Send message
              </Button>
            </StyledModal>
          )}

          {showTechnicalSupport && (
            <StyledModal
              isOpen={showTechnicalSupport}
              onBackgroundClick={() => setShowTechnicalSupport(false)}
              onEscapeKeydown={() => setShowTechnicalSupport(false)}
            >
              <div className={styles.modalHeader}>
                <H1>Having technical issues with STEEZY Studio?</H1>
                <CloseModalButton
                  onClick={() => setShowTechnicalSupport(false)}
                  variant="light"
                />
              </div>
              <div className={styles.supportContent}>
                <div className={styles.supportIcon}>
                  <span role="img" aria-label="Support">
                    👤
                  </span>
                </div>
                <P1>
                  We&apos;re here to help! If you can&apos;t find the answer
                  you&apos;re looking for in our FAQ&apos;s, reach out and let
                  us know what&apos;s going on. We&apos;ll help you figure it
                  out so you can get back to dancing!
                </P1>
              </div>
              <Button
                variant="primary"
                onClick={() => {
                  window.location.href = "mailto:support@steezy.co";
                }}
              >
                Send message
              </Button>
            </StyledModal>
          )}

          <div className={styles.surveyContent}>
            <div className={styles.surveyQuestion}>
              What would convince you to join again?
              <p className={styles.required}>REQUIRED</p>
            </div>
            <input
              type="text"
              className={styles.textInput}
              value={newSurveyData.joinAgainReason}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const target = e?.target;
                setNewSurveyData(prev => ({
                  ...prev,
                  joinAgainReason: target?.value || null,
                }));
              }}
              placeholder="Type one"
            />
          </div>

          <div className={styles.surveyContent}>
            <div className={styles.surveyQuestion}>
              How was your overall experience with STEEZY?
              <p className={styles.required}>REQUIRED</p>
            </div>
            <div className={styles.ratingGroup}>
              <div className={styles.ratingLabels}>
                <span>Not great</span>
                <span>Amazing</span>
              </div>
              <div className={styles.ratingOptions}>
                {Array.from({ length: 10 }, (_, i) => i + 1).map(num => (
                  <label
                    key={num}
                    className={styles.ratingLabel}
                    htmlFor={`rating-${num}`}
                  >
                    <input
                      id={`rating-${num}`}
                      type="radio"
                      name="rating"
                      value={num}
                      checked={newSurveyData.overallExperienceRating === num}
                      onChange={() =>
                        setNewSurveyData(prev => ({
                          ...prev,
                          overallExperienceRating: num,
                        }))
                      }
                    />
                    <span>{num}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div className={styles.surveyContent}>
            <div className={styles.surveyQuestion}>
              Anything else you&apos;d like to share about your experience?
            </div>
            <textarea
              className={styles.textarea}
              value={newSurveyData.additionalFeedback}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                const target = e?.target;
                setNewSurveyData(prev => ({
                  ...prev,
                  additionalFeedback: target?.value || null,
                }));
              }}
              placeholder="Start typing..."
            />
          </div>

          <div className={styles.surveyContent}>
            <div className={styles.agreementSection}>
              <label
                className={styles.agreementLabel}
                htmlFor="agreement-checkbox"
              >
                <input
                  id="agreement-checkbox"
                  type="checkbox"
                  checked={hasAgreed}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setHasAgreed(e.target.checked)
                  }
                />
                <span>
                  I understand that by proceeding, my subscription will be
                  canceled, and I will lose access to all premium features,
                  including unlimited classes, when my current subscription
                  period ends
                </span>
              </label>
            </div>
            <div className={styles.actions}>
              <Button
                variant="secondary"
                onClick={() => history.push("/account/subscription")}
              >
                Never mind
              </Button>
              <Button
                variant="primary"
                disabled={
                  !hasAgreed ||
                  !newSurveyData.reason ||
                  !newSurveyData.joinAgainReason ||
                  (newSurveyData.reason === CancelReason.Other &&
                    !newSurveyData.otherReason)
                }
                onClick={handleSubscriptionCancel}
              >
                Cancel my account
              </Button>
            </div>
          </div>
        </div>

        {(subscriptionCancelError || submitSurveyError) && (
          <Alert variant="danger">
            There was an error while canceling. Please try again or contact us!
          </Alert>
        )}
      </div>
    </div>
  );
};

export default (): JSX.Element => (
  <AccountBodyWrapper>
    <Cancel />
  </AccountBodyWrapper>
);
