import {
  SubscriptionCancelMutationVariables,
  useSubscriptionCancelMutation,
} from "services/graphql";

export function useSubscriptionCancel() {
  const [
    subscriptionCancel,
    { loading: subscriptionCancelLoading, error: subscriptionCancelError },
  ] = useSubscriptionCancelMutation();

  return {
    subscriptionCancel: (variables: SubscriptionCancelMutationVariables) => {
      return subscriptionCancel({
        variables,
      });
    },
    subscriptionCancelLoading,
    subscriptionCancelError,
  };
}
